import axios from 'axios';
import Vue from 'vue';
import store from './store';

if (store.state.user){
  Vue.use({
    install(Vue){
      Vue.prototype.$woo = axios.create({
          baseURL: store.state.user.user.linkloja,
          headers: {

            //'Content-Type': 'text/plain;charset=utf-8',
            'Accept': '*/*',
            Authorization: 'Basic ' + btoa(store.state.user.user.ck+ ":" +store.state.user.user.cs)
          }
      })
    }
  })
  
}