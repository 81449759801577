import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './config/router'
import store from './config/store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './config/axios'
import '@/assets/style.sass';
Vue.use(Buefy)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
