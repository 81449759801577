<template>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
/* eslint-disable */name: "Content"
}
</script>

<style>
.content{
    grid-area: content;
    margin-bottom: 0 !important;
    padding:40px 50px;
    background-color: #f1f5f9;
}
@media(max-width:992px){
  .content{
    padding:20px;
  }
}
</style>