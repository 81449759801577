<template>
    <a target="_blank" :href="'https://'+nomeLoja(this.$store.state.user.user.linkloja)"><h4 class="nomeLoja is-3 has-text-grey">{{ nomeLoja(this.$store.state.user.user.linkloja) }}</h4></a>
</template>

<script>
export default {
    /* eslint-disable */name: "Venda",
    data() {
        return {
            vendasData: null,
        }
    },
    created() {
        //this.vendas()
    },
    methods: {
        nomeLoja(nome) {
            var url = nome;
            var urlNoProtocol = url.replace(/^https?\:\/\//i, "");
            return urlNoProtocol;
        }
    }
}
</script>

<style>
@media(max-width:992px){
    .nomeLoja{
        display: none;
    }
}
</style>