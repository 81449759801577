<template>
  <div v-if="user" id="app">
    <HeaderVue />
    <MenuVue />
    <ContentVue />
    <FooterVue />
  </div>
  <div class="auth" v-else>
    <Auth></Auth>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import HeaderVue from './components/Layout/Header.vue';
import FooterVue from './components/Layout/Footer.vue';
import ContentVue from './components/Layout/Content.vue';
import MenuVue from './components/Layout/Menu.vue';
import Auth from '@/views/Auth/Auth.vue';
export default {
  components: { HeaderVue, FooterVue, ContentVue, MenuVue, Auth },
  computed: mapState(['user']),
}
</script>

<style scoped>
* {
  font-family: "Rubik", sans-serif;
  
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
}
body::-webkit-scrollbar {
  width: 5px !important;
}

body::-webkit-scrollbar-track {
  background: white !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #32a521 !important;
  border: 3px #000 !important; 
}
body {
  margin: 0;
  padding: 0;
}
#content-bg{
  padding: 40px ;
  background-color: white ;
  border-radius: 20px ;
}
.opacity {
  opacity: 0.6;
}

#app {
  height: 100vh;
  display: grid;
  grid-template-rows: 70px 1fr 20px;
  grid-template-columns: 200px 1fr;
  grid-template-areas:
    "menu header"
    "menu content"
    "menu footer";
}

.segundoValor {
  font-size: 12px;
  opacity: 0.6;
}

@media(max-width:600px) {
  .segundoValor {
    display: none;
  }

  #app {
    height: 100vh;
    background-color: #F1F5F9;
    display: flex;
    flex-direction: column;
  }
  #app aside, footer{
    display: none !important;
  }
}

/* Ajuste de layout de scroll */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background-color: #32a521;
  border: 3px #000;
}

/* Ajustar css tabelas */
.table tr.is-selected {
  background-color: #32a521 !important;
}

.table td {
  border: none !important;
}

.table-pedidos .level-right ul {
  margin-top: 0 !important;
}</style>
