const endPoins = {
    listAllOrders: '/wp-json/wc/v3/orders',
    listAllProducts: '/wp-json/wc/v3/products',
    listAllCupons: '/wp-json/wc/v3/coupons'
}

const ApiStrapi = {
    login: 'https://strapi.woocaixa.com.br/api/auth/local/',
    cadastro: "https://strapi.woocaixa.com.br/api/auth/local/register",
    senha: "https://strapi.woocaixa.com.br/api/auth/forgot-password",
    resetSenha: "https://strapi.woocaixa.com.br/api/auth/reset-password",
    me: "https://strapi.woocaixa.com.br/api/users/me?populate=*",
    atualizar: "https://strapi.woocaixa.com.br/api/users/"
}

const userAuth = '_woocaixa_user'

export { ApiStrapi, endPoins, userAuth}