import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    sideBar: false,
    ProfilePhoto: "@/assets/logo.png",
    user: null
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setProfile(state, Profile){
      if(Profile){
        state.ProfilePhoto = Profile
      }
    },
    setSidebar(state, openSidebar) {
      if (openSidebar === undefined) {
        state.sideBar = !state.sideBar
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
