<template>
  <footer>
    <h6 style="float: right;">Feito com 💚 pela <span style="color:#32A522">woocaixa</span></h6>
  </footer>
</template>

<script>
export default {
/* eslint-disable */name: "Footer"
}
</script>

<style>
footer{
    grid-area: footer;
    padding-right: 10px;
    background-color: #F1F5F9;
}
</style>