import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Painel',
    component: () => import( '../views/Painel/Painel.vue'),
  },
  {
    path: '/produtos',
    name: 'produtos',
    component: () => import( '../views/Products/Products.vue'),
  },
  {
    path: '/cupons',
    name: 'cupons',
    component: () => import( '../views/Cupons/Cupons.vue'),
  },
  {
    path: '/cupons/adicionar-novo',
    name: 'addCupom',
    component: () => import( '../components/Cupons/addCupom.vue'),
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import( '../views/Customer/Customer.vue'),
  },
  {
    path: '/cliente/:id',
    name: 'Cliente',
    component: () => import( '@/components/Customer/Cliente.vue'),
  },
  {
    path: '/minha-conta',
    name: 'MinhaConta',
    component: () => import( '../views/Account/my-account.vue'),
  },
  {
    path: '/status-loja',
    name: 'Status',
    component: () => import( '../components/Layout/Status.vue'),
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: () => import( '../views/Orders/Orders.vue'),
  },
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
