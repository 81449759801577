<template>
  <header>
    <nomeLoja></nomeLoja>
    <div class="iconeMenu">
      <img width="100" style="object-fit: contain;" src="@/assets/logoWooCaixa2.png">
      <b-button icon-pack="fas" icon-right="bars" @click="menuMobile = !menuMobile" />
      <!-- <b-icon pack="fas" icon="bars" @click="this.display = !this.display" size="is-small"></b-icon> -->
      <b-sidebar type="is-light" :fullheight="true" :right="false" :overlay="false" v-model="menuMobile">
        <div class="p-5">
          <b-menu>
            <b-menu-list label="Menu">
              <b-menu-item icon-pack="fas" icon="chart-simple" tag="router-link" to="/" label="Painel"></b-menu-item>
              <b-menu-item label="Pedidos" icon="truck" icon-pack="fas" tag="router-link" to="/pedidos"></b-menu-item>
              <b-menu-item label="Produtos" icon="box" icon-pack="fas" tag="router-link" to="/produtos"></b-menu-item>
              <b-menu-item label="Clientes" icon="user" icon-pack="fas" tag="router-link" to="/clientes"></b-menu-item>
              <b-menu-item label="Cupons" icon="percent" icon-pack="fas" tag="router-link" to="/cupons"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Configurações">
              <b-menu-item label="Minha conta" icon="user-pen" icon-pack="fas" tag="router-link"
                to="/minha-conta"></b-menu-item>
              <b-menu-item label="Status da loja" icon="gauge" icon-pack="fas" tag="router-link"
                to="/status-loja"></b-menu-item>
              <b-menu-item label="Sair" icon="door-open" icon-pack="fas" @click="sair"></b-menu-item>
            </b-menu-list>
            <b-menu-list label="Ajuda">
              <b-menu-item v-if="isIOS" label="Baixar APP" icon="apple" icon-pack="fa" @click="baixarApp"></b-menu-item>
              <b-menu-item v-if="isAndroid" label="Baixar APP" icon="android" icon-pack="fa" @click="baixarApp"></b-menu-item>
              <b-menu-item tag="a" href="https://woocaixa.com.br/bug" target="_blank" label="Bug`s" icon="bug" icon-pack="fas"></b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
      </b-sidebar>
    </div>
    <b-dropdown :triggers="['hover']" aria-role="list">
      <template #trigger>
        <b-button icon-pack="fas" type="is-text" icon-right="angle-down"> Olá, {{ userName }} </b-button>
      </template>
      <router-link to="/minha-conta"><b-dropdown-item aria-role="listitem">Minha conta</b-dropdown-item></router-link>
      <router-link to="/status-loja"><b-dropdown-item aria-role="listitem">Status da loja</b-dropdown-item></router-link>
      <b-dropdown-item @click="sair" aria-role="listitem">Sair</b-dropdown-item>
    </b-dropdown>
  </header>
</template>

<script>
import axios from 'axios';
import nomeLoja from '../Header/nomeLoja.vue';
export default {
/* eslint-disable */name: "Header",
  components: { nomeLoja },
  data() {
    return {
      userName: this.$store.state.user.user.username,
      ProfilePhoto: this.$store.state.ProfilePhoto,
      menuMobile: false,
      isAndroid: false,
      isIOS: false
    }
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isAndroid = /android/.test(userAgent);
    this.isIOS = /iphone|ipad|ipod|ios/.test(userAgent);
  },
  methods: {
    baixarApp() {
      if (window.navigator.standalone) {
        alert('Toque no ícone de compartilhamento e selecione "Adicionar à Tela Inicial" para usar como APP.');
      } else if (window.matchMedia('(display-mode: standalone)').matches) {
        alert('Toque no ícone de menu e selecione "Adicionar à Tela Inicial" para usar como APP');
      } else {
        alert('Para adicionar este site à tela inicial, toque no ícone de compartilhamento e selecione "Adicionar à Tela Inicial".');
      }
    },
    sair() {
      localStorage.removeItem("_woocaixa_user");
      localStorage.removeItem("vuex");
      localStorage.removeItem("Authorization");
      delete axios.defaults.headers.common['Authorization'];
      delete axios.defaults.auth;
      this.$store.commit('setUser', null)
      this.$router.push('/')
    }
  }
}
</script>

<style>
header {
  background-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ebebeb;
  grid-area: header;
  padding: 15px 30px;
  display: flex !important;
  flex-direction: row !important;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

header .button.is-text {
  text-decoration: none !important;
}

header .dropdown-trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-profile {
  border-radius: 30px;
  height: 30px;
  object-fit: cover;
  aspect-ratio: auto 30 / 30;
  width: 30px;
}

.iconeMenu {
  display: none;
}

@media(max-width:992px) {
  .iconeMenu {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  header .dropdown {
    display: none;
  }
}
</style>