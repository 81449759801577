<template>
  <div :style="{ height: mostrarForm ? '1200px' : '100vh' }" class="login">
    <div class="columns is-mobile">
      <div :style="{ height: mostrarForm ? '1200px' : '100vh' }" class="column is-4 bg-login">
        <h3 class="has-text-light ml-auto">sdasdsad</h3>
      </div>
      <div class="column">
        <div class="container inputs">
          <img class="logoWC" src="@/assets/logoWooCaixa2.png">
          <h3 class="title is-4 mt-5">Bem vindo(a)</h3>
          <p class="subtitle is-6 mt-5 opacity">Aqui você consegue gerenciar sua loja <br>WooCoomerce de uma maneira
            incrível</p>
          <b-button v-show="!this.$route.query.code" @click="mostrarForm = !mostrarForm" type="is-text">{{ trocarTxt
          }}</b-button>
          <!-- 
            FORM ESQUECI A SENHA
          -->
          <div v-if="this.$route.query.code">
            <div class="form-entrar mt-5">
              <h4 class="title is-4">Digite sua nova senha</h4>
              <b-field label-position="on-border" label="Senha">
                <b-input type="password" v-model="userNewPass.password" minlength="6" :message="mensagemInput"
                  icon-pack="fas" placeholder="Aquela senha para não esquecer" password-reveal>
                </b-input>
              </b-field>
              <b-field label-position="on-border" label="Confirme sua nova senha">
                <b-input type="password" v-model="userNewPass.confirmPassword" minlength="6" :message="mensagemInput"
                  icon-pack="fas" placeholder="De novo :)" password-reveal>
                </b-input>
              </b-field>
              <b-button v-show="mostrarInputLogin" :loading="carregandoBtn" @click="resetarSenha" type="is-success">Criar
                nova senha</b-button>
            </div>
          </div>
          <div v-else>
            <!-- 
            FORM REGISTRAR 
          -->
            <div v-if="mostrarForm" class="form-regitro mt-5">
              <h4 class="title is-4">Registre - se</h4>
              <b-field label-position="on-border" label="Email" :type="erroInput" :message="mensagemInput">
                <b-input type="email" v-model="userCadastro.email" placeholder="Escolha seu melhor e-mail :)">
                </b-input>
              </b-field>
              <b-field label-position="on-border" label="Nome de usuario" :type="erroInput" :message="mensagemInput">
                <b-input type="text" minlength="3" v-model="userCadastro.username" placeholder="Seu nickname">
                </b-input>
              </b-field>
              <b-field label-position="on-border" label="Senha">
                <b-input type="password" v-model="userCadastro.password" minlength="6" :message="mensagemInput"
                  icon-pack="fas" placeholder="Aquela senha para não esquecer" password-reveal>
                </b-input>
              </b-field>
                <b-field label-position="on-border" label="Link da sua loja">
                <b-input type="url" v-model="userCadastro.linkloja" placeholder="https://minhaloja.com.br"></b-input>
              </b-field>
              <b-field label-position="on-border" label="Consumer Key">
                <b-input v-model="userCadastro.ck" placeholder="ck_aXXXXXXXXXXXXXXXXX"></b-input>
              </b-field>
              <b-field label-position="on-border"  label="Consumer Secret">
                <b-input v-model="userCadastro.cs" placeholder="cs_aXXXXXXXXXXXXXXXXX"></b-input>
              </b-field>
              <b-button v-if="validado" @click="registrar" type="is-success">Registre-se</b-button>
              <b-button v-if="!validado" :loading="carregarValidado" @click="validarConnection" type="is-success">Validar
                conexão</b-button>
            </div>
            <!-- 
            FORM LOGIN 
          -->
            <div v-else class="form-entrar mt-5">
              <h4 class="title is-4">Entrar</h4>
              <b-field label-position="on-border" label="Email" :type="erroInput" :message="mensagemInput">
                <b-input type="email" required v-model="userLogin.identifier" placeholder="Escolha seu melhor e-mail :)">
                </b-input>
              </b-field>
              <b-field label-position="on-border" v-show="mostrarInputLogin" label="Senha">
                <b-input type="password" required v-model="userLogin.password" minlength="6" :message="mensagemInput"
                  icon-pack="fas" placeholder="Aquela senha para não esquecer" password-reveal>
                </b-input>
              </b-field>
              <b-button v-show="mostrarInputLogin" :loading="carregandoBtn" @click="entrar"
                type="is-success">Entrar</b-button>
              <b-button v-show="!mostrarInputLogin" :loading="carregandoBtn" @click="trocarSenha" type="is-success">Trocar
                senha</b-button>
              <b-button style="float: right;" @click="mostrarInputLogin = !mostrarInputLogin" type="is-text">Esqueci minha
                senha</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiStrapi, userAuth } from '@/config/api';
import axios from 'axios';
export default {
/* eslint-disable */name: "Auth",
  data() {
    return {
      mostrarInputLogin: true, //Mostrar inputs no esqueci minha senha
      erroInput: null,//Mostrar erros
      mostrarForm: false, //Trocar from
      validado: false, //Validar conexao com woocommerce
      carregarValidado: false, //Loading do botao
      userLogin: {}, //Array para logar
      userCadastro: {}, //Array para cadastro
      userNewPass: {}, //Array para resetar senha
      carregandoBtn: false, //Carregando btn de login
      mensagemInput: null //Mensagem
    }
  },
  created() {
    document.title = 'Entrar - Woocaixa';
  },
  computed: {
    trocarTxt() {
      return this.mostrarForm ? "Já tem conta aqui? Entrar" : "Não tem uma conta aqui? Registre-se";
    }
  },
  methods: {
    trocarSenha() {
      const body = {
        "email": this.userLogin.identifier
      }
      axios.post(ApiStrapi.senha, body).then(data => {
        this.$buefy.toast.open({
          message: 'Confira sua caixa de e-mail',
          type: 'is-success'
        })
      })
        .catch(error => {
          this.$buefy.toast.open({
            message: 'E-mail errado ou invalido',
            type: 'is-danger'
          })
        })
    },
    resetarSenha() {
      axios.post(ApiStrapi.resetSenha, {
        code: this.$route.query.code,
        password: this.userNewPass.password,
        passwordConfirmation: this.userNewPass.confirmPassword
      }).then(data => {
        data;
        this.$buefy.toast.open({
          message: 'Senha resetada com sucesso',
          type: 'is-success'
        })
      }).catch(e => {
        this.$buefy.toast.open({
          message: 'Ocorreu ao resetar a senha.',
          type: 'is-danger'
        })
      })
    },
    validarConnection() {
      this.carregarValidado = true;
      axios.get(this.userCadastro.linkloja + "/wp-json/wc/v3/orders", {
        headers: {
          Authorization: 'Basic ' + btoa(this.userCadastro.ck + ":" + this.userCadastro.cs)
        }
      }).then(data => {
        if(data){
          this.carregarValidado = false;
          this.validado = true;
          this.$buefy.toast.open({
            message: 'Validado com sucesso',
            type: 'is-success'
          })
          return;
        }
      }).catch(error => {
        this.carregarValidado = false;
        this.validado = false;
        this.$buefy.toast.open({
          message: 'Ocorreu um erro ao validar.',
          type: 'is-danger'
        })
      })
    },
    registrar() {
      axios.post(ApiStrapi.cadastro, this.userCadastro).then(res => {
        this.carregandoBtn = false;
        this.$store.commit('setUser', res.data)
        localStorage.setItem(userAuth, JSON.stringify(res.data))
        window.location.reload();
      }).catch(error => {
        this.carregandoBtn = false,
          this.$buefy.toast.open({
            message: 'Ocorreu um erro ao registrar. (' + error.message + ')',
            duration: 5000,
            type: 'is-danger'
          })
      })
    },
    entrar() {
      this.carregandoBtn = true;
      axios.post(ApiStrapi.login, this.userLogin).then(res => {
        this.carregandoBtn = false;
        this.$store.commit('setUser', res.data)
        localStorage.setItem(userAuth, JSON.stringify(res.data))
        window.location.reload();
      }).catch(error => {
        this.carregandoBtn = false,
          this.$buefy.toast.open({
            message: 'Ocorreu um erro ao entrar. (' + error.message + ')',
            duration: 5000,
            type: 'is-danger'
          })
      })
    }
  }
}
</script>

<style scoped>
.login {
  background-color: #f1f5f9;
}

.bg-login {
  background-position: 50% 50%;
  background-size: contain;
  background-image: url("@/assets/bgLogin2-min.jpg");
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  /*  background-size: cover; */
}

.inputs {
  padding: 80px;
}

.logoWC {
  width: 220px;
}

.form-entrar,
.form-regitro {
  background-color: white;
  width: 50%;
  border-radius: 10px;
  padding: 30px;
}

@media(max-width:992px) {
  .bg-login {
    display: none !important;
  }

  .inputs {
    padding: 30px;
  }

  .form-entrar,
  .form-regitro {
    width: 100%;
  }
}
</style>