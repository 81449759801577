<template>
  <aside>
    <div class="menu">
      <img width="150px" src="@/assets/logoWooCaixa2.png">
      <div class="mt-5">
        <router-link to="/"> <b-icon pack="fas" icon="chart-simple" size="is-medium"></b-icon><span
            class="menu-item">Painel</span></router-link>
        <router-link to="/pedidos"> <b-icon pack="fas" icon="truck" size="is-medium"></b-icon><span
            class="menu-item">Pedidos</span></router-link>
        <router-link to="/produtos"> <b-icon pack="fa" icon="box" size="is-medium"></b-icon><span
            class="menu-item">Produtos</span></router-link>
        <router-link to="/clientes"> <b-icon pack="fa" icon="user" size="is-medium"></b-icon><span
            class="menu-item">Clientes</span></router-link>
        <router-link to="/cupons"> <b-icon pack="fa" icon="percent" size="is-medium"></b-icon><span
            class="menu-item">Cupons</span></router-link>
      </div>
    </div>
    <div class="ajuda">
      <p>Centro de ajuda Acesse nossa base de conhecimento</p>
      <p>Achou algum bug? Reporte para gente</p>
    </div>
  </aside>
</template>

<script>
export default {
/* eslint-disable */name: "Menu",

}
</script>

<style>
/* Menu */
aside {
  grid-area: menu;
  padding: 20px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  border-right-color: #ebebeb;
  border-right-style: solid;
  border-right-width: 1px;
  background-color: #FFF;
}
.menu-item {
  margin-left: 20px;
  font-size: 16px;
}
aside a {
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  padding: 20px 10px;
  transition: 0.6s;
  border-radius: 5px;
  color: #c4c4c4 !important;
  transition: 0.6s;
}
aside a:hover {
  color: #32a521 !important;
  transition: 0.6s;
}
.router-link-exact-active{
  color: white !important;
  background-color: #32a521 !important;
  transition: 0.6s;
}
.router-link-exact-active i{
  color:white !important;
}
aside i {
  color: #32a521 !important;
}
/* Help */
.ajuda{
  background-image: url(@/assets/patternGreen.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  border-radius: 20px;
  padding:20px;
  text-align: center;
}
</style>